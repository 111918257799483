import { ClientInfo } from "~/dto/oauth2.dto";
import { rootApiService } from "./@global";

const ENDPOINT = {
    CLIENT: "api/public/social/sso/client/{clientId}".trim(),
    USER: "/api/public/points/point-user".trim(),

}

export class Oauth2Service {

    async getClientInfo({ clientId }: { clientId: string }) {
        const pathUrl = ENDPOINT.CLIENT.replace("{clientId}", clientId)
        return rootApiService.get<ClientInfo>(pathUrl);
    }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Oauth2Service();
