import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom"

import Cookies from 'js-cookie';


export const Oauth2CallbackView = () => {


    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    useEffect(() => {
        if (token) {
            const redirectUrl = localStorage.getItem("redirectUrl") || "";
            if (redirectUrl) {
                Cookies.set('accessToken', token, { expires: 365 * 10, path: '/' });
                window.location.href = `${redirectUrl}?accessToken=${token}`;
            } else {
                navigate("/login")
            }

        } else {
            navigate("/login")
        }
    }, [navigate, token]);

    return null;
}