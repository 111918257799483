import React from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs, } from '@chakra-ui/react'
import BoxLayout from '~/components/BoxLayout';
import { LoginView } from "../LoginView";

const ScreensUserLazy = React.lazy(() =>
  import("../UserView")
    .then(({ UserView }) => ({ default: UserView })),
);

const ScreensTicketLazy = React.lazy(() =>
  import("../TicketView")
    .then(({ TicketView }) => ({ default: TicketView })),
);

const ScreensOrganLazy = React.lazy(() =>
  import("../OrganiView")
    .then(({ OrganiView }) => ({ default: OrganiView })),
);

const HomeView = () => {

  return (
    <BoxLayout>

      <LoginView />

    </BoxLayout>
  )

}

export default HomeView;

