import {
  Button,
  Center,
  HStack,
  VStack,
  Image,
  Text,
  Box,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { configEnv } from "~/@config";
import BoxLayout from "~/components/BoxLayout";
import oauth2Service from "~/services/oauth2.service";
import IcTw from "~/assets/icon_socials/ic_x.png";

const { SSO } = configEnv().CONNECTORS;

export const LoginView = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const clientId = params.get("clientId");
  const redirectUrl = params.get("redirectUrl");

  const [callbackUrl, setCallbackUrl] = useState("");

  const loadClientInfo = useCallback(async () => {
    try {
      const clientInfo = await oauth2Service.getClientInfo({ clientId });
      setCallbackUrl(clientInfo?.callBackUrl);
    } catch (error) {}
  }, [clientId]);

  useEffect(() => {
    localStorage.setItem("clientId", clientId || "");
    localStorage.setItem("redirectUrl", redirectUrl || "");
    loadClientInfo();
  }, [redirectUrl, clientId, loadClientInfo]);

  const onLogin = useCallback(() => {
    if (callbackUrl) {
      window.location.href =
        `${SSO.baseUrl}/auth/twitter?redirect_uri=${callbackUrl}&client=${clientId}`.trim();
    }
  }, [callbackUrl]);
  return (
    <VStack w="full">
      <BoxLayout>
        <VStack w="full">
          <Text
            color={`#000`}
            fontSize={{ base: "24px", md: "30px" }}
            fontWeight={700}
            pb="50px"
          >
            Social Labs Login
          </Text>

          <Button
            w={{ base: "280px", md: "400px" }}
            p="10px 20px"
            h="50px"
            borderStyle={"solid"}
            bg={`#FFECBA`}
            borderRadius={"8px"}
            onClick={onLogin}
          >
            <HStack>
              <Image
                src={IcTw}
                w="20px"
                h="20px"
                cursor={{ base: "none", md: "pointer" }}
              />
              <Text color={`#000`} fontSize={{ base: "14px", md: "16px" }}>
                Log in with Twitter
              </Text>
            </HStack>
          </Button>
        </VStack>
      </BoxLayout>
    </VStack>
  );
};
